.avatarContainer,
.selectedAvatarContainer {
  position: relative;
  overflow: hidden;
  display: inline-block;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  padding: 0;
}

.avatarContainer {
  border: 2px solid transparent;
}

.selectedAvatarContainer {
  border: 2px solid var(--primary-color);
}

.avatar,
.selectedAvatar {
  width: 100%;
  height: 100%;
}

.checkboxContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
}