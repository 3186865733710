.chatContainer {
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 30px 30px 0 0;
  width: 100%;
  height: 90%;
  top: 0;
  z-index: 2;
  pointer-events: none;
  display: flex;
  flex-direction: column;
}

.row {
  display: inline-flex;
  flex-grow: 1;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .chatContainer {
    border-radius: 0;
    height: 98%;
  }
}