.individualMessage {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  color: #fff;
  font-size: var(--responsive-fontSize);
  text-shadow: 0px 0px 3px #000000;
  text-align: left;
  opacity: var(--base-opacity);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  width: max-content;
  z-index: 10;
  padding: 8px 18px;
}

@keyframes scroll-animation {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: -200%;
  }
}

.special {
  height: auto;
  left: 0;
  margin: 50px 0;
  background: linear-gradient(90deg, rgba(189, 0, 255, 0.6), rgba(255, 122, 242, 0.6), rgba(255, 199, 0, 0.6));
  background-size: 120%;
  border-radius: 100px;
  background-position: 91% 100%;
  text-shadow: 0px 0px 3px rgb(56, 0, 57);
  padding: 10px 24px;

}

.emojiSpecial {
  height: auto;
  left: 0;
  margin: 50px 0;
  text-shadow: 0px 0px 3px rgb(56, 0, 57);
  margin: 0;
  background: linear-gradient(90deg, rgba(189, 0, 255, 0.6), rgba(255, 199, 0, 0.6));
  background-size: 120%;
  border-radius: 100px;
  padding: 12px 17px;
}

.scrollSpeed1 {
  animation: calc(var(--base-speed) + 1s) scroll-animation linear forwards;
}

.scrollSpeed2 {
  animation: calc(var(--base-speed) + 2s) scroll-animation linear forwards;
}

.scrollSpeed3 {
  animation: calc(var(--base-speed) + 3s) scroll-animation linear forwards;
}

.scrollSpeed4 {
  animation: calc(var(--base-speed) + 4s) scroll-animation linear forwards;
}

.scrollSpeed5 {
  animation: calc(var(--base-speed) + 6s) scroll-animation linear forwards;
}

@keyframes Animation {
  0% {
    background-position: 10% 0%
  }

  50% {
    background-position: 50% 100%
  }

  100% {
    background-position: 10% 50%
  }
}

@keyframes gradient-shift {
  0% {
    background: linear-gradient(90deg, rgba(189, 0, 255, 0.6), rgba(255, 122, 242, 0.6), rgba(255, 199, 0, 0.6));
  }

  50% {
    background: linear-gradient(180deg, rgba(189, 0, 255, 0.6), rgba(255, 122, 242, 0.6), rgba(255, 199, 0, 0.6));
  }

  100% {
    background: linear-gradient(270deg, rgba(189, 0, 255, 0.6), rgba(255, 122, 242, 0.6), rgba(255, 199, 0, 0.6));
  }
}

@media screen and (max-width: 500px) {
  .special {
    padding: 6px 12px;
  }
  .emojiSpecial {
    padding: 8px 11px;
  }
}